<template>
  <div class="pricing">
    <Steps :current="step" :link="link" :mode="mode" :id="id"></Steps>
    <div class="container p-4 editCourse__container">
      <div class="row">
        <div class="col-12">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form class="login__formContent" @submit.prevent="handleSubmit(save)">
              <label for="price" class="form-label text-start d-inline-block w-100">課程定價</label>
              <ValidationProvider rules='required' v-slot='{ errors , classes }'>
                <div :class="classes">
                  <input
                    type="number"
                    name="price"
                    v-model="price"
                    class="form-control mb-1"
                    id="price"
                    required
                    @change="savePrice;change = true"
                  >
                  <span class="text-danger mt-1">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <!-- <div class="row mb-3">
                <div class="col-12 col-md-4">
                <label for="price" class="form-label text-start d-inline-block w-100">課程定價</label>
                <ValidationProvider rules='required' v-slot='{ errors , classes }'>
                  <div :class="classes">
                    <input
                      type="number"
                      name="price"
                      v-model="price"
                      class="form-control mb-1"
                      id="price"
                      required
                      @change="savePrice;change = true"
                    >
                    <span class="text-danger mt-1">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                </div>
                <div class="col-12 col-md-7">
                  <label class="form-label text-start d-inline-block w-100">效期</label>
                  <select class="form-select" aria-label="courses__filter">
                    <option disabled value>請選擇效期</option>
                    <option value="永久">永久</option>
                    <option value="一年">一年</option>
                    <option value="三年">三年</option>
                  </select>
                </div>
                <div class="col-12 col-md-1">
                  <button>
                    增加
                  </button>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12 col-md-4">
                <label for="price" class="form-label text-start d-inline-block w-100">課程定價</label>
                <ValidationProvider rules='required' v-slot='{ errors , classes }'>
                  <div :class="classes">
                    <input
                      type="number"
                      name="price"
                      v-model="price"
                      class="form-control mb-1"
                      id="price"
                      required
                      @change="savePrice;change = true"
                    >
                    <span class="text-danger mt-1">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                </div>
                <div class="col-12 col-md-7">
                  <label class="form-label text-start d-inline-block w-100">效期</label>
                  <select class="form-select" aria-label="courses__filter">
                    <option disabled value>請選擇效期</option>
                    <option value="永久">永久</option>
                    <option value="一年">一年</option>
                    <option value="三年">三年</option>
                  </select>
                </div>
                <div class="col-12 col-md-1">
                  <button>
                    增加
                  </button>
                </div>
              </div> -->
              <div class="d-flex justify-content-start my-3 mb-4">
                <input type="checkbox"
                  id="label" class="mt-0 me-2 form-check-input" v-model="isFree">
                <label>將該課程設為免費</label>
              </div>
              <label class="form-label text-start d-inline-block w-100">銷售模式</label>
              <div class="d-flex justify-content-start align-items-center my-2">
                <input type="checkbox" class="btn-check" id="b2c" value='b2c' v-model="pattern" @change="checkClick();changePattern('b2c')">
                <label class="btn btn-main-outline me-3" for="b2c" style="width: 8rem;">B2C</label>
                <input type="checkbox" class="btn-check" id="b2b" value="b2b" v-model="pattern" @change="checkClick();changePattern('b2b')">
                <label class="btn btn-main-outline" for="b2b" style="width: 8rem;">B2B</label>
              </div>
              <p class="text-danger mt-1 text-start">{{ errorStr }}</p>
              <div v-if="pattern.includes('b2b') && role == 'Admin'" class="text-start mt-4">
                <h3 class="mb-2">業務銷售資訊</h3>
                <textarea
                  v-model="tempContent"
                  class="w-100" rows="5"
                >
                </textarea>
                <p class="text-danger mt-1 text-start">{{ emptyContact }}</p>
              </div>
              <div class="d-flex justify-content-start align-items-center">
                <button class="btn btn-main mt-3 w-25 me-2" v-if="mode == 'launch'" @click.prevent="toLastStep">上一步</button>
                <button class="btn btn-main mt-3 w-25" >{{ mode == 'launch'? '發布' : '儲存' }}</button>
                <a class="editCourse__back mt-3 w-50 text-start" style="margin-left: 1rem;" v-if="mode == 'edit'" @click.prevent="backToMyCourse">
                  返回至我的課程頁<i class="fas fa-chevron-right ms-1"></i>
                </a>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <Meta :title ="metaTitle" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Steps from '../components/Steps';

const Swal = require('sweetalert2');

export default {
  name: 'Edit3',
  data() {
    return {
      step: 3,
      link: '/dashboard/edit_course/pricing',
      price: 0,
      isFree: false,
      mode: 'launch',
      goNext: false,
      localCourse: {},
      editData: '',
      id: '',
      change: false,
      pattern: [],
      emptyContact: '',
      errorStr: '',
      tempContent: '',
      metaTitle: '編輯課程 - 元學堂',
      priceGrp: [
        { price: 100, expiration: '一年' },
        { price: 100, expiration: '三年' },
        { price: 100, expiration: '不限' },
      ]
    };
  },
  components: {
    Steps,
  },
  metaInfo: {
    title: "編輯課程 - 元學堂",
    meta: [
      { name: 'description', content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。' },
      {
        property: 'og:title',
        content: '編輯課程 - 元學堂',
      },
      {
        property: 'og:description',
        content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。',
      },
    ]
  },
  watch: {
    isFree() {
      if (this.isFree) {
        this.price = 0;
        this.$log.debug('price');
      }
    },
    price() {
      if(this.price > 0) {
        this.isFree = false;
      }
    }
  },
  methods: {
    save() {
      const vm = this;
      if (this.pattern.length == 0) {
        this.emptyContact = '請填寫業務資訊';
        return;
      }

      if (this.pattern.includes('b2b') && this.tempContent == '' && this.role === 'Admin') {
        this.emptyContact = '請填寫業務資訊';
        return;
      } else if (this.pattern.includes('b2b') && this.tempContent == '' && this.role === 'Teacher') {
        this.tempContent = '待審核';
      }
      this.emptyContact = '';

      vm.goNext = true;
      if (vm.mode == 'edit') {
        this.$store.dispatch('courseModule/setCourseData', { price: vm.price, b2Bcontact: vm.tempContent, b2BContact: vm.tempContent });
        const data = {
          ...this.course, price: vm.price, b2Bcontact: this.tempContent, b2BContact: vm.tempContent,
        };
        vm.$log.debug(data);

        vm.$http.put(`${this.$API_PATH}/Course`, data, {
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
            Authorization: `bearer ${vm.userAuth}`,
          },
        }).then((res) => {
          vm.goNext = true;
          Swal.fire(
            '',
            `${res.data.message}`,
            'success',
          );
        }).catch((err) => {
          vm.$log.debug(err.response);
          if (err.response) {
            Swal.fire(
              '',
              `${err.response.data.message}`,
              'info',
            );
          } else {
            Swal.fire(
              '錯誤',
              '請稍候再試',
              'info',
            );
          }
        });
      } else {
        // let course = JSON.parse(localStorage.getItem('Course'));
        // course.Price = this.price;
        // localStorage.setItem('Course', JSON.stringify(course));
        this.$store.dispatch('courseModule/setCourseData', { price: vm.price, b2Bcontact: vm.tempContent, b2BContact: vm.tempContent });
        vm.$log.debug(this.course);
        this.$http.post(`${this.$API_PATH}/Course`, this.course, {
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
            Authorization: `bearer ${this.userAuth}`,
          },
        }).then((res) => {
          vm.goNext = true;
          localStorage.removeItem('Course');
          localStorage.removeItem('syllabus');
          Swal.fire(
            '',
            `${res.data.message}`,
            'success',
          );
          setTimeout(() => {
            vm.$store.dispatch('courseModule/resetCourseData');
            vm.$router.push('/dashboard/courses');
          }, 3000);
        }).catch((err) => {
          vm.$log.debug(err.response);
          Swal.fire(
            '錯誤',
            `${err.response.data.message}`,
            'info',
          );
        });
      }
    },
    toLastStep() {
      const vm = this;
      vm.goNext = true;
      vm.$router.push('/dashboard/edit_course/syllabus');
    },
    checkClick() {
      if (this.pattern.length == 0) {
        this.errorStr = '請選擇銷售模式';
      } else {
        this.errorStr = '';
      }
    },
    checkLocal() {
      // this.localCourse = JSON.parse(localStorage.getItem('Course'));
      this.price = this.course.price;
      if (this.price == 0) {
        this.isFree = true;
      } else {
        this.isFree = false;
      }
    },
    getEditData() {
      this.$http.get(`${this.$API_PATH}/Course?CourseId=${this.id}`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        if (res.data.data) {
          this.$store.dispatch('courseModule/getEditData', res.data.data);
          this.$store.dispatch('courseModule/setCourseData', { trailVideoId: res.data.data.trailerVideoId });
          this.tempContent = this.course.b2BContact ? this.course.b2BContact : '';
          this.price = this.course.price;
          if(this.price == 0) {
            this.isFree = true;
          }
          // console.log(this.course);
          if (this.course.isB2C) {
            this.pattern.push('b2c');
          }
          if (this.course.isB2B) {
            this.pattern.push('b2b');
          }
        }
      }).catch((err) => {
        this.goNext = true;
        this.$router.push('/error');
        this.$log.debug(err.response);
      });
    },
    savePrice() {
      this.$store.dispatch('courseModule/setCourseData', { price: this.price });
      this.checkLocal();
    },
    backToMyCourse() {
      this.$router.push('/dashboard/courses');
    },
    changePattern(str) {
      if (this.pattern.indexOf('b2b') >= 0) {
        this.$store.dispatch('courseModule/setCourseData', { isB2B: true });
      } else if (this.pattern.indexOf('b2b') == -1) {
        this.tempContent = '';
        this.$store.dispatch('courseModule/setCourseData', { isB2B: false });
      }
      if (this.pattern.indexOf('b2c') >= 0) {
        this.$store.dispatch('courseModule/setCourseData', { isB2C: true });
      } else if (this.pattern.indexOf('b2c') == -1) {
        this.$store.dispatch('courseModule/setCourseData', { isB2C: false });
      }
    },
    ...mapActions(['getUserAuth', 'getUserAuthFromLocal', 'checkExpAuth', 'updateLoading']),
  },
  computed: {
    ...mapGetters(['userName', 'Avatar', 'userId', 'role', 'exp', 'userAuth', 'LoginProvider']),
    ...mapGetters('courseModule', ['course']),
  },
  beforeRouteLeave(to, from, next) {
    const vm = this;
    if (vm.goNext || vm.change == false) {
      if (this.mode == 'edit') {
        localStorage.removeItem('Course');
        localStorage.removeItem('syllabus');
        vm.$store.dispatch('courseModule/resetCourseData');
        next();
      } else {
        next();
      }
    } else {
      let text = '';
      if (this.mode == 'edit') {
        text = '尚未儲存這次的更新，請按儲存以儲存您的修改。';
      } else {
        text = '提醒您，上架課程將不會暫存您的變更，需完成至第四步驟完成發布，本系統即會儲存並發布您的課程。';
      }
      Swal.fire({
        title: '',
        text,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: '取消',
        confirmButtonText: '離開',
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem('Course');
          localStorage.removeItem('syllabus');
          vm.$store.dispatch('courseModule/resetCourseData');
          next();
        }
      });
    }
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.mode = 'edit';
      this.getEditData();
    } else {
      this.checkLocal();
      this.mode = 'launch';
      localStorage.setItem('step', '3');
    }
    localStorage.setItem('step', '3');
  },
  mounted() {
    window.addEventListener('beforeunload', (e) => {
      const confirmationMessage = '你還沒有完成你的文章，就這樣離開了嗎？';

      (e || window.event).returnValue = confirmationMessage; // Gecko + IE
      return confirmationMessage; // Webkit, Safari, Chrome
    });
  },
  destroyed() {
    window.removeEventListener('beforeunload', (e) => {
      const confirmationMessage = '你還沒有完成你的文章，就這樣離開了嗎？';

      (e || window.event).returnValue = confirmationMessage; // Gecko + IE
      return confirmationMessage; // Webkit, Safari, Chrome
    });
  },
};
</script>

<style lang="scss" scoped>
  .invalid .text-danger {
    text-align: left;
    width: 100%;
    display: block;
    font-size: 12px;
  }

  .invalid input {
    border: 1px #EB0600 solid;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(252, 67, 21, 0.25);
    }
  }
  .btn-check:checked + .btn-main-outline {
    background-color: #012970;
    color: white;
  }
</style>
